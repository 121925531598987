import React from 'react'
import {Card, Col, Image, Row, Table, Tag} from "antd";
import {getAPI} from "../../../utils/apiRequest";
import {OVERVIEW_DATA, LOCATION_USER_LIST} from "../../../constants/api";
import InfiniteFeedLoaderButton from "../../../common/InfiniteFeedLoaderButton";
import {displayMessage} from "../../../utils/common";
import {ERROR_MSG_TYPE} from "../../../constants/dataKeys";
import {Link} from "react-router-dom";
import lockr from "lockr";
import {_debounce,} from "../../../utils/lodashUtils";
import Search from "antd/es/input/Search";
import {DatePicker} from 'antd';
import moment from "moment";

class UserHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locationData: [],
            location_count: null,
            device_count: null,
            loading: false,
            co2_saved: null,
            inverter_count: null,
            capacity: null,
            location_total: null,
            sortedInfo: null,
            date: moment().format('YYYY-MM-DD'),
            selected_date: null
        }
        this.searchString = _debounce(this.searchString, 500)
    }

    componentDidMount() {
        let token = lockr.get('token')
        if (token) {
            this.loadOverviewInfo()
            this.loadLocations();
        } else {
            this.props.history.push('/auth/user/login')
        }

    }

    loadOverviewInfo(page = 1) {
        let that = this;
        this.setState({
            loading: true
        });
        let successFn = function (data) {
            that.setState({
                device_count: data.device_count,
                location_count: data.location_count,
                co2_saved: data.co2_saved,
                inverter_count: data.inverter_count,
                capacity: data.capacity,
                loading: false
            })
        }
        let errorFn = function () {
            that.setState({
                loading: false
            });
        }
        let params = {
            ...page,
            action_taken: true
        }
        getAPI(OVERVIEW_DATA, successFn, errorFn, {...params})
    }

    loadLocations(page = 1) {
        let that = this;
        const {searchString} = this.state;
        this.setState({
            loading: true
        });
        let successFn = function (data) {
            console.log(data)
            if (data.current === 1) {
                that.setState({
                    location_total: data.count,
                    nextPage: data.next,
                    locationData: data.results,
                    loading: false
                })
            } else {
                that.setState(function (prevState) {
                    return {
                        total: data.count,
                        nextPage: data.next,
                        locationData: [...prevState.locationData, ...data.results],
                        loading: false
                    }
                })
            }
        }
        let errorFn = function (error) {
            that.setState({
                loading: false
            });
            Object.values(error).map(item =>
                displayMessage(ERROR_MSG_TYPE, item)
            );
        }
        let params = {
            page,
            date: this.state.selected_date,
            action_taken: true,
            name__icontains: searchString || undefined
        }
        getAPI(LOCATION_USER_LIST, successFn, errorFn, {...params})
    }

    setAgeSort = () => {
        this.setState({
            sortedInfo: {
                order: 'descend',
                columnKey: 'age',
            },
        });
    };
    handleChange = (pagination, filters, sorter) => {
        // console.log('Various parameters', pagination, filters, sorter);
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    };
    searchString = (value) => {
        this.setState(function (prevState) {
            return {searchString: value}
        }, function () {
            this.loadLocations();
        })
    }


    onChangeDate = (value) => {
        let that = this
        this.setState({selectedd_date: value}, () =>
            console.log(""));
        that.setState({
            selected_date: moment(value).format('YYYY-MM-DD')
        }, function () {
            that.loadLocations()
        })
    }

    render() {

        const {
            device_count, location_count, capacity, co2_saved, inverter_count,
            locationData, loading, date
        } = this.state;
        let {sortedInfo} = this.state;
        sortedInfo = sortedInfo || {};

        // const {usersData} = this.state;
        const columns = [
            {
                title: "S.No.",
                key: "s_no",
                fixed: "left",
                align: "center",
                render: (item, record, index) => locationData.indexOf(record) + 1
            },
            {
                title: "Site Name",
                key: "name",
                dataIndex: 'name',
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
                ellipsis: true,
                align: "center",
                render: (item, record) =>
                    <Link
                        to={{
                            pathname: "/devices/" + record.id.toString() + "/" + record.name
                        }}
                    >
                        {record.name}
                    </Link>
            }, {
                title: "alarms",
                key: "address",
                dataIndex: 'alarms',
                align: "center",
                render: (item, record) => {
                    if (record.summary.status === "Offline") {
                        return <p>--</p>;
                    }
                    if (record.summary.alarm_status === "On-Error") {
                        return <center><Tag color="red" style={{
                            borderRadius: "20px",
                        }}>On-Error</Tag></center>;
                    }
                    if (record.summary.alarm_status === "OK") {
                        return <center><Tag color="green" style={{
                            borderRadius: "20px",
                        }}>OK</Tag></center>;
                    }
                    return <p>--</p>;
                }
            }, {
                title: "DC Capacity(KWp)",
                key: "capacity",
                dataIndex: 'capacity',
                responsive: ['md'],
                align: "center",
                render: (item, record) => {
                    if (record.capacity !== null) {
                        return record.capacity
                    } else {
                        return "-"
                    }
                },
            }, {
                title: "Communication Status",
                key: "address",
                dataIndex: 'alarms',
                align: "center",
                render: (item, record) => {
                    if (record.summary.status === "Offline") {
                        return <center><Tag color="red" style={{
                            borderRadius: "20px",
                        }}>Offline</Tag></center>;
                    }
                    if (record.summary.status === "Online") {
                        return <center><Tag color="green" style={{
                            borderRadius: "20px",
                        }}>Online</Tag></center>;
                    }
                    return <center><Tag color="red" style={{
                        borderRadius: "20px",
                    }}>Offline</Tag></center>;
                }
            }, {
                title: "E Today(KWh)",
                key: "address",
                dataIndex: 'alarms',
                align: "center",
                render: (item, record) => {
                    if (record.summary.daily_energy !== null) {
                        return record.summary.daily_energy !== 0 ? parseFloat(record.summary.daily_energy).toFixed(2) : record.summary.daily_energy;
                    } else {
                        return "-"
                    }
                },
            }, {
                title: "OAP(KW)",
                key: "address",
                dataIndex: 'alarms',
                align: "center",
                render: (item, record) => {
                    if (record.summary.op_active_power !== null) {
                        return record.summary.op_active_power !== 0 ? parseFloat(record.summary.op_active_power).toFixed(2) : record.summary.op_active_power;
                    } else {
                        return "-"
                    }
                },
            }, {
                title: "PR(%)",
                key: "address",
                dataIndex: 'alarms',
                align: "center",
                render: (item, record) => {
                    if (record.summary.pr !== null) {
                        return record.summary.pr !== 0 ? parseFloat(record.summary.pr).toFixed(2) : record.summary.pr;
                    } else {
                        return "-"
                    }
                },
            }, {
                title: "SY(KWh/kwp)",
                key: "address",
                dataIndex: 'alarms',
                align: "center",
                render: (item, record) => {
                    if (record.summary.specific_yields !== null) {
                        return record.summary.specific_yields !== 0 ? parseFloat(record.summary.specific_yields).toFixed(2) : record.summary.specific_yields;
                    } else {
                        return "-"
                    }
                },
            }, {
                title: "CUF(%)",
                key: "address",
                dataIndex: 'alarms',
                align: "center",
                render: (item, record) => {
                    if (record.summary.cuf !== null) {
                        return record.summary.cuf !== 0 ? parseFloat(record.summary.cuf).toFixed(2) : record.summary.cuf;
                    } else {
                        return "-"
                    }
                },
            }, {
                title: "Irradiation(W/m2)",
                key: "address",
                dataIndex: 'alarms',
                align: "center",
                render: (item, record) => {
                    if (record.summary.irradiation !== null) {
                        return record.summary.irradiation !== 0 ? parseFloat(record.summary.irradiation).toFixed(2) : record.summary.irradiation;
                    } else {
                        return "-"
                    }
                },
            }, {
                title: "Insolation(KWh/m2)",
                key: "address",
                dataIndex: 'alarms',
                align: "center",
                render: (item, record) => {
                    if (record.summary.insolation !== null) {
                        return record.summary.insolation !== 0 ? parseFloat(record.summary.insolation).toFixed(2) : record.summary.insolation;
                    } else {
                        return "-"
                    }
                },
            }
        ];
        const gridStyle = {
            width: '50%',
        };

        return (
            <div style={{fontFamily: "Open Sans Reguler"}}>
                <div style={{fontWeight: 'bold', fontSize: 30}}>
                    <center><h1>Account Overview</h1></center>
                </div>
                <div className="site-card-wrapper">
                    <Row justify="center" gutter={1}>
                        <Col>
                            <Card bordered={true} style={{
                                margin: "20px",
                                borderRadius: "20px",
                                overflow: "hidden",
                                fontSize: 12,
                            }}>
                                <Card.Grid style={{width: '50%',}}>
                                    <Row justify="space-between">
                                        <Col span={12}>
                                            <Image preview={false} className={"logo"} src={'/location2.png'}/>
                                        </Col>
                                        <Col span={12}>
                                            <center><h4>Sites</h4>
                                            <h4>{location_count}</h4>
                                        </center>
                                        </Col>
                                    </Row>
                                </Card.Grid>
                                <Card.Grid style={{width: '50%',}}>
                                    <Row justify="space-between">
                                        <Col span={12}>
                                            <Image preview={false} className={"logo"} src={'/device2.png'}/>
                                        </Col>
                                        <Col span={12}>
                                            <center><h4>Units</h4>
                                                <h4>{device_count}</h4>
                                            </center>
                                        </Col>
                                    </Row>
                                </Card.Grid>
                            </Card>
                        </Col>
                        <Col>
                            <Card bordered={true} style={{
                                margin: "20px",
                                borderRadius: "20px",
                                overflow: "hidden",
                                fontSize: 12,
                                align: "center",

                            }}>
                                <Row justify="space-between">
                                    <Col>
                                        <Image preview={false} className={"logo"} src={'/capasity.png'}/>
                                    </Col>
                                    <Col>
                                        <center><h4>Capacity(Kwp)</h4>
                                            <h4>{capacity}</h4>
                                        </center>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col>
                            <Card bordered={true} style={{
                                margin: "20px",
                                borderRadius: "20px",
                                overflow: "hidden",
                                fontSize: 12
                            }}>
                                <Row justify="space-between">
                                    <Col>
                                        <Image preview={false} className={"logo"} src={'/wms.png'}/>
                                    </Col>
                                    <Col>
                                        <center><h5>WMS</h5>
                                            <h4>{"0"}</h4>
                                        </center>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col>
                            <Card bordered={true} style={{
                                margin: "20px",
                                borderRadius: "20px",
                                overflow: "hidden",
                                fontSize: 12,
                            }}>
                                <Card.Grid style={{width: '50%',}}>
                                    <Row justify="space-between">
                                        <Col span={12}>
                                            <Image preview={false} className={"logo"} src={'/meter.png'}/>
                                        </Col>
                                        <Col span={12}>
                                            <center><h4>Meter</h4>
                                                <h4>0</h4>
                                            </center>
                                        </Col>
                                    </Row>
                                </Card.Grid>
                                <Card.Grid style={{width: '50%',}}>
                                    <Row justify="space-between">
                                        <Col span={12}>
                                            <Image preview={false} className={"logo"} src={'/inverter.png'}/>
                                        </Col>
                                        <Col span={12}>
                                            <center><h4>Inverter</h4>
                                                <h4>{inverter_count}</h4>
                                            </center>
                                        </Col>
                                    </Row>
                                </Card.Grid>
                            </Card>
                        </Col>
                        <Col>
                            <Card bordered={true} style={{
                                margin: "20px",
                                borderRadius: "20px",
                                overflow: "hidden",
                                fontSize: 12
                            }}>
                                <Row justify="space-between">
                                    <Col>
                                        <Image preview={false} className={"logo"} src={'/co2.png'}/>
                                    </Col>
                                    <Col>
                                        <center><h4>CO2 Saved ( lbs)</h4>
                                            <h4>{co2_saved !== 0 ? parseFloat(co2_saved).toFixed(2) : co2_saved}</h4>
                                        </center>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <br/>
                <div style={{display: 'block'}}>
                    <Row>
                        <Col span={22}>
                            <DatePicker allowClear={false} style={{width: "200"}}
                                        defaultValue={moment(date, 'YYYY/MM/DD')}
                                        formate={'DD/MM/YYYY'}
                                        onChange={(value) => this.onChangeDate(value)}/>
                        </Col>
                        <Col span={2}>
                            <a>Map view</a>
                        </Col>
                    </Row>
                    <br/>
                    <Search placeholder="Search Location" onChange={(e) => this.searchString(e.target.value)}/>

                    {/*<Input placeholder={"Search Location.."} onChange={(e) => this.searchString(e.target.value)}*/}
                    {/*       className={"mb-10"} icon={<SearchOutlined />}/>*/}
                    <br/>
                    <br/>
                    <Table columns={columns} loading={loading} dataSource={locationData} pagination={false}
                           size='small' onChange={this.handleChange} scroll={{x: 1300}}/>
                    <InfiniteFeedLoaderButton
                        loaderFunction={() => this.loadLocations(this.state.nextPage)}
                        hidden={!this.state.nextPage}
                    />
                </div>
            </div>
        );
    }
}

export default UserHome;